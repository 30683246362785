/* Navbar css */
@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@200..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Albert+Sans:wght@200..900&display=swap");

.nav {
  background-color: #333;
  display: flex;
  justify-content: center;
  position: fixed; /* Fixes the nav bar to the bottom of the screen */
  bottom: 0; /* Positions the nav bar at the bottom */
  width: 100%; /* Ensures the nav bar spans the entire width of the screen */
  z-index: 1000; /* Keeps the nav bar on top of other elements */
  box-shadow: 0px +2px 5px rgba(0, 0, 0, 0.2); /* Adds a shadow to the bottom of the nav bar */
}

.navList {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  width: 100%; /* Ensures the icons are spaced evenly */
}

.navItem {
  flex: 1; /* Ensures each nav item takes up equal space */
  display: flex;
}

.navLink {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: white;
  width: 100%; /* Ensures the link takes up the full width of the nav item */
  padding: 5px 0 5px 0; /* Adds padding to the top and bottom of the link */
}

.navLinkActive {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: white;
  width: 100%; /* Ensures the link takes up the full width of the nav item */
  padding: 10px 0;
  background-color: #06c; /* Background color for the active link */
}

.icon {
  width: 28px;
  height: 28px;
  cursor: pointer;
  margin-bottom: 5px;
}

.icon:hover {
  filter: brightness(1.2);
}

.label {
  font-size: 9px;
  font-family: "Albert Sans", sans-serif;
}
