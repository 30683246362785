/* WelcomePage CSS */
@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@200..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Albert+Sans:wght@200..900&display=swap");

.welcomePage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #212121;
  height: 100vh;
  width: 100vw;
  margin: 0;
  position: relative;
  overflow: hidden;
}

.fadeOut {
  transform: scale(0.5);
  transform: translateX(-100vh);
  transition: transform 0.3s ease-in-out;
  transition-behavior: smooth;
  background-color: #212121;
}

.created {
  font-size: 12px;
  color: #212121;
  background-color: whitesmoke;
  font-family: "Inconsolata", sans-serif;
  position: fixed;
  top: 0;
  margin: 25px;
  border-radius: 3px;
}

.createdBy {
  padding: 2px 6px;
  margin: 0;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.welcomeMessage1 {
  font-size: 12px;
  color: white;
  background-color: #212121;
  margin: 15px 0 5px 0;
  font-family: "Inconsolata", monospace;
}

.welcomeMessage2 {
  font-size: 32px;
  color: white;
  background-color: #212121;
  margin: 0 0 15px 0;
  padding: 0 10px;
  font-family: "Albert Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

.welcomeImage {
  max-height: 100px;
  width: auto;
}

.scrollContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  margin-top: 20px;
}

.scrollContent {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 300%;
}

.scrollItem {
  flex: 1 0 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.scrollImage {
  max-height: 44px;
  width: auto;
  margin-top: 120px;
}

.scrollText {
  font-size: 12px;
  font-family: "Inconsolata", sans-serif;
  color: white;
}

.dots {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.dot {
  height: 7px;
  width: 7px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.dot.active {
  background-color: #06c;
}

.continueButton {
  font-size: 12px;
  color: white;
  background-color: #06c;
  padding: 10px 20px;
  text-decoration: none;
  font-family: "Inconsolata", sans-serif;
  font-weight: 600;
  position: fixed;
  bottom: 8px;
  left: 8px;
  right: 8px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  border-radius: 5px;
}

.continueButton:hover {
  background-color: #005bb5;
}
