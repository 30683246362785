/* HomePage CSS */
@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@200..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Albert+Sans:wght@200..900&display=swap");

.homePage {
  margin: 0;
  padding: 80px 0 90px 0;
  background-color: #212121;
  width: 100vw;
}

.homeContent {
  margin: 0;
  padding: 0;
}

.highlightedTeam .teamName {
  color: #ffffff;
  font-weight: 900;
  padding: 2px 3px;
  background-color: #212121;
  border-radius: 4px;
}

.liveTitle,
.recentTitle {
  margin: 0 0 10px 0;
  padding: 30px 0 0 0;
  font-size: 14px;
  font-family: "Segoe UI", sans-serif;
  color: #ffffff;
  text-align: center;
}

.recentTitle {
  padding: 30px 0 0 0;
}

.liveContent,
.recentContent {
  margin: 0;
  padding: 0;
}

.cardLiveMatches {
  background-color: #b22222;
  padding: 20px;
  border-radius: 0px;
  text-align: center;
  color: white;
  grid-column: span 2;
  cursor: default;
}

.cardLiveMatches h2 {
  margin-bottom: 20px;
}

.liveMatchesContainer,
.recentMatchesContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
  margin: 0 15px;
}

.whistle {
  width: 50px;
  height: 50px;
  opacity: 0.5;
  padding: 20px 0 5px 0;
}

.noLiveContainer,
.noRecentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  margin: 10px 15px 0 15px;
  border: #3f3f3f solid 1px;
  border-radius: 5px;
}

.noLiveAlert,
.noRecentAlert {
  color: white;
  font-size: 10px;
  font-family: "Segoe UI", sans-serif;
  padding: 0 0 10px 0;
  opacity: 0.5;
}

.collapsedLine {
  width: auto;
  height: 1px;
  background-color: #3f3f3f;
  margin: 0px 18px;
}

.matchCard {
  background-color: #fffafa;
  border-radius: 5px;
  padding: 10px;
  text-align: left;
  width: auto;
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.matchRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
}

.matchDateTime {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin-bottom: 8px;
}

.matchTime {
  font-size: 10px;
  font-family: "Inconsolata", sans-serif;
  font-weight: 500;
  color: #212121;
  text-align: left;
  flex: 1;
}

.matchDate {
  font-size: 10px;
  font-family: "Inconsolata", sans-serif;
  font-weight: 500;
  color: #212121;
  text-align: right;
  flex: 1;
}

.team {
  display: flex;
  align-items: center;
}

.teamLogo {
  width: 22px;
  height: 22px;
  margin-right: 10px;
  box-shadow: #212121 0px 0px 2px;
}

.teamName {
  font-size: 10px;
  font-family: "Albert Sans", sans-serif;
  font-weight: 700;
  color: #212121;
}

.teamScores {
  display: flex;
  align-items: center;
}

.teamGoals {
  font-size: 10px;
  font-family: "Segoe UI", sans-serif;
  color: #212121;
  margin-right: 5px;
}

.teamScore {
  color: #212121;
  font-family: "Inconsolata", sans-serif;
  font-size: 20px;
  font-weight: 800;
}

.matchDetails {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  color: #212121;
  font-size: 10px;
  font-family: "Inconsolata", sans-serif;
  font-weight: 500;
}
