/* Header CSS */
@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@200..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Albert+Sans:wght@200..900&display=swap");

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1000;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  background-color: #1e0405;
  color: white;
  width: 100%;
  height: 80px;
  padding: 20px 20px 15px 20px;
  box-sizing: border-box;
}

.headerContent {
  margin-bottom: 3px;
}

.headerImg {
  max-height: 64px;
  width: auto;
  margin: 0;
}

.h1 {
  font-size: 24px;
  color: white;
  margin: 0;
  font-family: "Albert Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

.settingsImg {
  height: 18px;
  width: auto;
  margin: 0;
  position: absolute;
  top: 110px;
  right: 16px;
  padding: 3px;
  border-radius: 15px;
  cursor: pointer;
  background-color: #212121;
}

.modal {
  position: fixed;
  top: 155px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
  z-index: 2000;
  border-radius: 5px;
  text-align: center;
}

.modal h2 {
  margin: 0;
  font-family: "Albert Sans", sans-serif;
  font-size: 14px;
  color: #212121;
  font-weight: 900;
  padding: 0;
  text-align: left;
  margin-left: 12px;
}

.dropdown {
  margin-top: 10px;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 14px;
  font-family: "Inconsolata", monospace;
  font-weight: 500;
}
