/* All Rounds CSS */
.allRoundsPage {
  margin: 0;
  padding: 80px 0 5px 0;
  background-color: #212121;
  width: 100vw;
}

.allRoundsTitle {
  margin: 0 0 10px 0;
  padding: 30px 0 0 0;
  font-size: 14px;
  font-family: "Segoe UI", sans-serif;
  color: #ffffff;
  text-align: center;
}

.allRoundsContent {
  margin: 0;
  padding: 0;
}

/* Dropdown Button Styles */
.allRoundsDropdown {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  margin: 10px 15px 0 15px;
  border-radius: 5px;
}

.dropbtn {
  background-color: #06c;
  color: white;
  padding: 10px;
  font-size: 14px;
  font-family: "Segoe UI", sans-serif;
  font-weight: bold;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
}

.dropdownContent {
  display: flex;
  flex-direction: row;
  background-color: whitesmoke;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 5px;
  width: 100%;
  margin: 5px 15px 0 15px;
  max-height: 470px;
  overflow-y: auto;
}

/* Pseudo-element for the fade effect */
.dropdownContent::after {
  content: "";
  border-radius: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 35px; /* Adjust height for how much of a fade you want */
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    whitesmoke 100%
  );
  pointer-events: none; /* Ensure it doesn't block interactions */
}

.dropdownColumn {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  margin: 0;
  width: 50%;
}

.dropdownHeading {
  font-size: 14px;
  font-weight: 900;
  margin: 0;
  padding: 15px 20px 10px 20px;
  width: 100%;
  font-family: "Albert Sans", sans-serif;
  border-right: 1px solid #ccc; /* Add a bottom border to each item */
  color: #212121;
}

.dropdownList {
  list-style-type: none;
  padding: 0;
  width: 100%;
  border-top: 1px solid #ccc; /* Add a bottom border to each item */
  border-right: 1px solid #ccc; /* Add a bottom border to each item */
  margin: 0;
}

.dropdownItem {
  color: black;
  padding: 15px 20px;
  text-decoration: none;
  display: block;
  cursor: pointer;
  font-size: 14px;
  font-family: "Inconsolata", monospace;
  font-weight: 500;
  border-bottom: 1px solid #ccc; /* Add a bottom border to each item */
}

.dropdownItem:hover {
  background-color: #06c;
  color: whitesmoke;
  border-radius: 5px;
}

/* Match Results Container */
.matchResultsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  margin: 15px 15px;
  border-radius: 5px;
  padding: 0 0 0 0;
}

.matchResults {
  width: 100%;
}

.selectedRoundTitle {
  color: #ffffff;
  font-size: 14px;
  font-family: "Segoe UI", sans-serif;
  text-align: center;
}

.matchDayHeader {
  display: flex;
  justify-content: space-between;
  font-size: 10px;
  font-weight: bold;
  color: #fff;
  background-color: #06c;
  padding: 5px 15px;
  border-radius: 5px;
  text-align: left;
  font-family: "Segoe UI", sans-serif;
}

.matchCard {
  background-color: #f5f5f5;
  border-radius: 5px;
  margin: 5px 0;
  padding: 10px 10px 10px 10px;
}

.teamNamesRow {
  display: grid;
  grid-template-columns: 1.25fr 1fr 0.5fr 1fr 1.25fr;
  margin-bottom: 5px;
  font-family: "Albert Sans", sans-serif;
  font-weight: 700;
  font-size: 10px;
}

.teamNameHome {
  display: grid;
  grid-column: 2;
  justify-items: left;
}

.teamNameAway {
  display: grid;
  grid-column: 4;
  justify-items: right;
}

.matchDetails {
  display: grid;
  grid-template-columns: 1.25fr 1fr 0.5fr 1fr 1.25fr;
  align-items: center;
}

.matchTime {
  grid-column: 1;
  font-size: 11px;
  justify-self: start;
  font-family: "Inconsolata", monospace;
  font-weight: 700;
  padding-left: 5px;
}

.teamHome {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.teamAway {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.teamLogoHome {
  width: 22px;
  height: 22px;
  margin-right: 5px;
  box-shadow: #212121 0px 0px 2px;
}

.teamLogoAway {
  width: 22px;
  height: 22px;
  margin-left: 5px;
  box-shadow: #212121 0px 0px 2px;
}

.teamScoreHome {
  font-size: 16px;
  padding-left: 8px;
  font-family: "Inconsolata", monospace;
  font-weight: 700;
}

.teamScoreAway {
  font-size: 16px;
  padding-right: 8px;
  font-family: "Inconsolata", monospace;
  font-weight: 700;
}

.vs {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vs img {
  width: 15px;
  margin: 0;
}

.matchStatus {
  grid-column: 5;
  font-size: 11px;
  font-family: "Inconsolata", monospace;
  font-weight: 700;
  text-align: right;
  justify-self: end;
  padding-right: 5px;
}

/* Venue Row */
.venueRow {
  display: flex;
  justify-content: center;
  margin-top: 3px;
}

.venue {
  font-size: 9px;
  font-family: "Albert Sans", sans-serif;
  font-weight: 600;
}
