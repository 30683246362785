/* Footer CSS */
.footer {
  font-size: 10px;
  background-color: #212121;
  color: white;
  text-align: center;
  bottom: 0;
  width: 100vw;
  height: 160px;
  margin: 0;
}

.footerTrademarks {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 20px;
  height: 60px;
  font-size: 9px;
  font-family: "Segoe UI", sans-serif;
}

.footerCreated {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding-top: 5px;
  font-family: "Segoe UI", sans-serif;
  font-size: 9px;
}

.footerName {
  font-weight: 600;
}
