/* Match Lookup CSS */
.matchLookupPage {
  margin: 0;
  padding: 80px 0 30px 0;
  background-color: #212121;
  width: 100vw;
}

.matchLookupTitle {
  margin: 0 0 10px 0;
  padding: 30px 0 0 0;
  font-size: 14px;
  font-family: "Segoe UI", sans-serif;
  color: #ffffff;
  text-align: center;
}

.matchLookupContent {
  margin: 0;
  padding: 0;
}

.filterMenu {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 10px 15px 0 15px;
  width: auto;
  border: 1px solid #3f3f3f;
  border-radius: 5px;
  padding: 10px 20px;
  background-color: #2e2e2e;
}

.leftFilters,
.rightFilters {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.filterColumn {
  position: relative;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.filterColumn label {
  width: 60px;
  font-size: 12px;
  margin-right: 10px;
}

.filterColumn input[type="text"] {
  width: 140px;
  padding: 6.5px;
  border: 1px solid #3f3f3f;
  border-radius: 3px;
  background-color: #424242;
  color: #ffffff;
  box-sizing: border-box;
  font-size: 11px;
}

.filterColumn input[type="text"]::placeholder {
  color: #aaaaaa;
}

.filterColumn input[type="text"]:placeholder-shown + .yearIndicator {
  display: block;
}

.yearIndicator {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #ff0000;
  font-size: 12px;
  display: none;
  pointer-events: none;
}

/* Custom dropdown styling */
.customDropdown {
  position: relative;
  width: 140px;
}

.dropdownHeader {
  padding: 6px;
  border: 1px solid #3f3f3f;
  border-radius: 3px;
  background-color: #424242;
  color: #ffffff;
  cursor: pointer;
  font-size: 11px;
}

.dropdownList {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #3f3f3f;
  border-radius: 3px;
  background-color: #424242;
  list-style: none;
  margin: 0;
  padding: 0;
  z-index: 1000;
  font-size: 11px;
}

.dropdownListItem {
  padding: 5px;
  cursor: pointer;
}

.dropdownListItem:hover {
  background-color: #555555;
}

.filterCheckboxes {
  display: flex;
  flex-direction: column;
}

.filterCheckboxes label {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  color: #ffffff;
  font-size: 10px;
  cursor: pointer;
}

.filterCheckboxes input[type="checkbox"] {
  margin-right: 5px;
  appearance: none; /* Remove default checkbox styling */
  width: 16px;
  height: 16px;
  border: 2px solid #ffffff;
  border-radius: 4px;
  background-color: #2e2e2e;
  position: relative;
  cursor: pointer;
}

.filterCheckboxes input[type="checkbox"]:checked {
  background-color: #007bff;
  border-color: #007bff;
}

.filterCheckboxes input[type="checkbox"]:checked::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 3.5px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.filterCheckboxes input[type="checkbox"]:disabled {
  border-color: #aaaaaa;
  background-color: #555555;
}

.filterCheckboxes input[type="checkbox"]:disabled + label {
  color: #aaaaaa;
}

.searchButtonContainer {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.searchButton {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  margin: 0 15px 5px 15px;
}

.searchButton:hover {
  background-color: #0056b3;
}

.resultsContainer {
  margin: 0 15px;
  width: auto;
  color: #ffffff;
}

.gameDetails {
  margin-bottom: 5px;
  padding: 10px;
  border: 1px solid #3f3f3f;
  border-radius: 5px;
  background-color: #2e2e2e;
}

.roundYearRow {
  display: flex;
  font-weight: bold;
  padding-top: 3px;
  font-size: 14px;
  font-family: "Inconsolata", monospace;
}

.roundname {
  width: 50%;
  text-align: start;
}

.year {
  width: 50%;
  text-align: end;
}

.teamRow {
  display: flex;
  font-weight: bold;
  padding: 3px 0;
  font-size: 14px;
  font-family: "Albert Sans", sans-serif;
}

.teamName {
  width: 70%;
}

.teamScore {
  width: 30%;
  display: flex;
  justify-content: flex-end;
}

.venueDateRow {
  display: flex;
  font-size: 12px;
  padding-bottom: 10px;
  font-family: "Inconsolata", monospace;
}

.venue {
  width: 50%;
}

.date {
  width: 50%;
  text-align: right;
}

.winnerRow {
  font-weight: normal;
  font-size: 11px;
  text-align: center;
  padding: 5px 0 5px 0;
  font-family: "Inconsolata", monospace;
  display: flex;
  align-items: center;
  justify-content: end;
}

.winnerLabel {
  margin-right: 5px;
  font-weight: normal;
  font-family: "Albert Sans", sans-serif;
  font-size: 10px;
}

.winner {
  font-weight: bold;
  color: #0cb60c;
}

.noDataText,
.loadingText {
  font-size: 10px;
  text-align: center;
  margin-top: 20px;
  color: #ffffff;
}

.teamLogo {
  width: 22px;
  height: 22px;
  margin-right: 10px;
  box-shadow: #212121 0px 0px 2px;
  vertical-align: middle;
  box-shadow: #212121 0px 0px 2px;
}
