.aboutPage {
  padding: 2rem;
}

.aboutTitle {
  font-size: 2rem;
  color: #003057;
}

.aboutContent {
  margin-top: 1rem;
}
