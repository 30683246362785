/* Footy Ladder CSS */
@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@200..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Albert+Sans:wght@200..900&display=swap");

.footyLadderPage {
  margin: 0;
  padding: 80px 0 0px 0;
  background-color: #212121;
  width: 100vw;
}

.footyLadderTitle {
  margin: 0 0 10px 0;
  padding: 30px 0 0 0;
  font-size: 14px;
  font-family: "Segoe UI", sans-serif;
  color: #ffffff;
  text-align: center;
}

.footyLadderContent {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loadingText {
  color: white;
  padding-top: 20px;
  font-size: 10px;
  font-family: "Segoe UI", sans-serif;
  text-align: center;
}

.ladderTable {
  width: 100%;
  margin: 0 auto 10px auto;
  border-collapse: collapse;
}

.ladderTable th,
.ladderTable td {
  border-top: #212121 solid 1px;
  border-bottom: #212121 solid 1px;
  padding: 4.5px 0 4.5px 0;
}

.ladderTable th {
  background-color: #06c;
  border-top: 2px solid #06c;
  border-bottom: 2px solid #06c;
  text-align: center;
  color: #ffffff;
  font-size: 12px;
  font-family: "Segoe UI", sans-serif;
  height: 26px;
}

.ladderTable tr:nth-child(even) {
  text-align: center;
  background-color: #333333;
  color: #ffffff;
  font-size: 11px;
  font-family: "Segoe UI", sans-serif;
}

.ladderTable tr:nth-child(odd) {
  text-align: center;
  background-color: #212121;
  color: #ffffff;
  font-size: 11px;
  font-family: "Segoe UI", sans-serif;
}

.topEightText {
  color: #ffffff;
  font-size: 7px;
  font-family: "Segoe UI", sans-serif;
  font-weight: 700;
  text-align: center;
  border-bottom: 3px solid #06c;
}

.topEightRow {
  background-color: #06c !important;
  border-top: 2px solid #06c;
  border-bottom: 2px solid #06c;
}

.highlightedTeam {
  color: #212121;
  font-weight: 900;
  background-color: #ffffff;
  padding: 1px 3px;
  border-radius: 4px;
}
